import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';

const requestsInfoByTokenQuery = gql`
  query requestsInfoByToken {
    requestsInfoByToken {
      property {
        _id
        name
      }
      client {
        _id
        name
        configs {
          logoImage {
            url
          }
          requestsApp {
            branding
          }
          projectPriorities {
            id
            priority
            amount
            unit
          }
        }
      }
    }
  }
`;

// getRequestInfoByQueryData :: QueryData -> PropertyInfo
// PropertyInfo = {
//   propertyName: String,
//   clientName: String,
//   branding: String,
//   logoImageUrl: String
// }
const getRequestInfoByQueryData = R.compose(
  R.applySpec({
    propertyName: R.pathOr('', ['property', 'name']),
    clientName: R.path(['client', 'name']),
    branding: R.path(['client', 'configs', 'requestsApp', 'branding']),
    logoImageUrl: R.path(['client', 'configs', 'logoImage', 'url']),
    projectPriorities: R.pathOr([], ['client', 'configs', 'projectPriorities']),
  }),
  R.prop('requestsInfoByToken'),
);

export const useRequestInfoByTokenData = (isAuthorized) => {
  const { data, loading } = useQuery(requestsInfoByTokenQuery, {
    skip: !isAuthorized,
  });

  return {
    ...getRequestInfoByQueryData(data),
    loading,
  };
};
